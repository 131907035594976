<template>
  <div>
    <p class="text-center text-14 text-bold p-b-5">程序化能力</p>

    <Row :gutter="32" class="m-b-20">
      <i-col span="12" class="text-right">
        <span class="title">角色</span>
        <RadioGroup v-model="chooseFeature" size="small" type="button" button-style="solid">
            <Radio label="1">业主</Radio>
            <Radio label="2">代理商</Radio>
            <Radio label="3">供应商</Radio>
        </RadioGroup>
      </i-col>
      <i-col span="12">
        <span class="title">查询类型</span>
        <RadioGroup v-model="chooseActType" size="small" type="button" button-style="solid">
          <Radio label="1">活跃率</Radio>
          <Radio label="2">覆盖率</Radio>
      </RadioGroup>
      </i-col>
    </Row>

    <div style="width:100%;height:400px" id="analysisProgrammingChartReport"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { listUserActivityOrPlantGbDate } from '@/api/dw/login'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      chooseFeature: '1', // 角色类型
      chooseActType: '1', // 查询类型

      dataSourcePie: null
    }
  },
  methods: {
    initPageData () {
      const queryModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        companyType: this.chooseFeature,
        actType: this.chooseActType
      }

      listUserActivityOrPlantGbDate(queryModel).then(res => {
        const legends = []
        const xLabels = []
        const ySeries = []

        res.forEach(element => {
          if (!legends.includes(element.s)) {
            legends.push(element.s)
          }

          if (!xLabels.includes(element.x)) {
            xLabels.push(element.x)
          }
        })

        legends.forEach(element => {
          ySeries.push({
            name: element,
            type: 'line',
            smooth: true, // 实现平滑效果
            animationDuration: 2000,
            data: res.filter(x => x.s === element).map(x => parseFloat(x.y))
          })
        })

        this.initReportChart(legends, xLabels, ySeries)
      })
    },
    initReportChart (legends, xLabels, ySeries) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('analysisProgrammingChartReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('analysisProgrammingChartReport'))
          const option = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: legends,
              right: 0,
              textStyle: {
                color: '#fff'
              }
            },
            grid: {
              left: 0,
              right: 0,
              bottom: 0,
              top: 50,
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: xLabels,
              axisLabel: {
                color: '#c5c8ce'
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: false
              },
              axisLine: {
                show: true
              }
            },
            series: ySeries
          }

          this.dataSourcePie.setOption(option, true)
          const that = this
          window.addEventListener('resize', function () {
            that.dataSourcePie.resize()
          })
        }
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    }
  },
  watch: {
    chooseFeature () {
      this.initPageData()
    },
    chooseActType () {
      this.initPageData()
    }
  }
}
</script>
