import request from '@/utils/requestV2'

// 统计用户登录的次数
export function countUserLoginNumber (data) {
  return request({
    url: '/ooh-dw/v1/datav/login/countUserLoginNumber',
    method: 'get',
    params: data
  })
}
// 用户最近的登录时间
export function getLastLoginTime (data) {
  return request({
    url: '/ooh-dw/v1/datav/login/getLastLoginTime',
    method: 'get',
    params: data
  })
}
// 统计用户活跃度或功能覆盖率
export function listUserActivityOrPlantGbDate (data) {
  return request({
    url: '/ooh-dw/v1/datav/login/listUserActivityOrPlantGbDate',
    method: 'get',
    params: data
  })
}
