<template>
  <div class="center-block-compare-date">
    <span class="title">查询周期</span>
    <DatePicker v-model="queryModel.startDate" type="date" transfer placeholder="Select date" size="small" style="width: 110px" :clearable="false" @on-change="handleChangeDate"></DatePicker>
    <span class="m-l-5 m-r-5">至</span>
    <DatePicker v-model="queryModel.endDate" type="date" transfer placeholder="Select date" size="small" style="width: 110px" :clearable="false" @on-change="handleChangeDate"></DatePicker>

    <span class="title m-l-20">对比周期</span>
    <DatePicker v-model="compareModel.startDate" type="date" transfer placeholder="Select date" size="small" style="width: 110px" :clearable="false" @on-change="handleChangeCompareDate"></DatePicker>
    <span class="m-l-5 m-r-5">至</span>
    <DatePicker v-model="compareModel.endDate" type="date" transfer placeholder="Select date" size="small" style="width: 110px" :clearable="false" @on-change="handleChangeCompareDate"></DatePicker>

    <span class="title m-l-20">报表下载：</span>
    <a @click="handleDownload(1)" class="m-r-5">客户</a>
    <a @click="handleDownload(2)" class="m-r-5">财务</a>
    <a @click="handleDownload(3)" class="m-r-5">合约</a>
    <a @click="handleDownload(4)" class="m-r-5">履约</a>
    <a @click="handleDownload(5)">产品</a>

    <span class="title m-l-20">其他功能：</span>
    <a @click="hanldeShowProgramming">查看程序化能力</a>
    <Modal v-model="modalProgramming" width="1000" :footer-hide="true">
      <Programming ref="programming"/>
    </Modal>

  </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import Programming from '../bottomComponents/Programming'
import { ParseDate } from '@/utils/dateFormat'

export default {
  components: {
    Programming
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      modalProgramming: false,

      queryModel: {
        endDate: '',
        startDate: ''
      },

      compareModel: {
        endDate: '',
        startDate: ''
      }
    }
  },
  created () {
    this.queryModel.startDate = this.searchSchedule.startDate
    this.queryModel.endDate = this.searchSchedule.endDate

    this.compareModel.startDate = this.compareSchedule.startDate
    this.compareModel.endDate = this.compareSchedule.endDate
  },
  methods: {
    handleDownload (param) {
      let url = ''
      let fileName = ''
      switch (param) {
        case 1: // 客户
          url = '/manage/exportAdvertiserReport'
          fileName = '态势经分(客户).xlsx'
          break
        case 2: // 财务
          url = '/manage/exportBusinessReport'
          fileName = '态势经分(财务).xlsx'
          break
        case 3: // 合约
          url = '/excel/exportContractReport'
          fileName = '态势经分(合约).xlsx'
          break
        case 4: // 履约
          url = '/excel/exportPromiseReport'
          fileName = '态势经分(履约).xlsx'
          break
        case 5: // 产品
          url = '/excel/exportProductReport'
          fileName = '态势经分(产品).xlsx'
          break
        default:
          break
      }

      const downloadUrl = process.env.VUE_APP_API_URL_V2 + '/ooh-dw/v1/dw' + url
      let downloadParams = {
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        compareStartDate: this.compareSchedule.startDate,
        compareEndDate: this.compareSchedule.endDate
      }

      if (param === 1 && this.$store.getters.token.publisherSetting.financialBoard === 2) {
        downloadParams = Object.assign({}, downloadParams, { industryType: 'inter_industry' })
      }

      downloadFileRequest(downloadUrl, downloadParams, fileName)
    },
    hanldeShowProgramming () {
      this.modalProgramming = true
      this.$refs.programming.initPageData()
    },
    handleChangeDate () {
      const schedule = {
        startDate: ParseDate(this.queryModel.startDate),
        endDate: ParseDate(this.queryModel.endDate)
      }

      this.$store.commit('set_situation_searchSchedule', schedule)
    },
    handleChangeCompareDate () {
      const schedule = {
        startDate: ParseDate(this.compareModel.startDate),
        endDate: ParseDate(this.compareModel.endDate)
      }

      this.$store.commit('set_situation_compareSchedule', schedule)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    compareSchedule () {
      return this.$store.state.situationWeb.compareSchedule
    }
  }
}
</script>

<style>
.center-block-compare-date{
    position: fixed;
    top: 45px;
    left:470px;
    right:0;
    z-index: 1000;
}
</style>
